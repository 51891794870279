<!--  -->
<template>
	<div>
		<div class='renwu-choose'>
			<div v-for="(item,index) in renwuList" :key='index' class='renwu-item' @click="chooseRenwu(index)"
				:style='isAdd.add&&index!=2?"cursor: pointer":"cursor:not-allowed"'>
				<img :src='item.src' />
				<p>{{item.title}}</p>
				<div v-show='chooseRenwuIndex==index' class='renwu-model'>
					<i class='el-icon-check'></i>
				</div>
			</div>
		</div>
		<el-form label-position='left' label-width="150px" ref='allForm' :model='allForm'>
			<!-- v-for循环截取循环的数组-->
			<el-form-item v-for="(item) in allText.slice(0,1)" :key='item.id' :label="item.title" :prop='item.formName'
				:rules="[{ required: true, message: '请输入'+item.title, trigger: 'change' }]">
				<el-checkbox-group v-model="allForm[item.formName]">
					<el-checkbox v-for="item in communitys" :key="item.levelcode" :label="item.levelcode">{{item.name}}
					</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item v-for="(item) in allText.slice(1,2)" :key='item.id' :label="item.title" :prop='item.formName'
				:rules="[{ required: true, message: '请输入'+item.title, trigger: 'blur' }]">
				<el-input v-model="allForm[item.formName]" :placeholder="'请输入'+item.title"></el-input>
			</el-form-item>
			<el-form-item v-for="(item) in allText.slice(2,4)" :key='item.id' :label="item.title" :prop='item.formName'
				:rules="[{ required: true, message: '请输入'+item.title, trigger: 'change' }]">
				<el-date-picker style="width:45%" value-format='yyyy-MM-dd' format='yyyy-MM-dd'
					v-model='allForm[item.formName]' type="date" :placeholder="'请选择'+item.title"></el-date-picker>
			</el-form-item>
			<el-form-item v-for="(item) in allText.slice(4,5)" :key='item.id' :label="item.title" :prop='item.formName'
				:rules="[{ required: true, message: '请输入'+item.title, trigger: 'change' }]">
				<el-input-number style="width:30%" v-model="allForm[item.formName]" :min=1></el-input-number>
			</el-form-item>
			<el-form-item v-for="(item) in allText.slice(5,6)" :key='item.id' :label="item.title" :prop='item.formName'
				:rules="[{ required: true, message: '请输入'+item.title, trigger: 'blur' }]">
				<el-input-number style="width:30%" v-model="allForm[item.formName]" :min=1></el-input-number>
			</el-form-item>
			<!-- <el-form-item v-for="(item) in allText.slice(6,7)" :key='item.id' :label="item.title" :prop='item.formName'
				:rules="[{ required: true, message: '请输入'+item.title, trigger: 'change' }]">
				<el-select style="width:45%" v-model="allForm[item.formName]" :placeholder="'请选择'+item.title">
					<el-option label="可见" value='1'></el-option>
					<el-option label="不可见" value='0'></el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item v-for="(item) in allText.slice(7,8)" :key='item.id' :label="item.title" :prop='item.formName'
				:rules="[{ required: true, message: '请输入'+item.title, trigger: 'blur' }]">
				<el-input v-model="allForm[item.formName]" :placeholder="'请输入'+item.title"></el-input>
			</el-form-item>
			<el-form-item v-for="(item) in allText.slice(8,9)" :key='item.id' :label="item.title" :prop='item.formName'
				:rules="[{ required: true, message: '请输入'+item.title, trigger: 'blur' }]">
				<el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="allForm[item.formName]" :placeholder="'请输入'+item.title"></el-input>
			</el-form-item>

			<el-form-item v-show='chooseRenwuIndex==0' v-for="(item) in shiTingText.slice(0,1)" :key='item.id'
				:label="item.title">
				<el-select style="width:45%" v-model="shiTingForm[item.formName]" :placeholder="'请选择'+item.title">
					<el-option label="文本类" value='0'></el-option>
					<el-option label="视频类" value='1'></el-option>
					<el-option label="音频类" value='2'></el-option>
				</el-select>
			</el-form-item>
			<el-form-item v-show='chooseRenwuIndex==0' v-for="(item) in shiTingText.slice(1,2)" :key='item.id'
				:label="item.title">
				<el-select style="width:45%" v-model="shiTingForm[item.formName]" :placeholder="'请选择'+item.title">
					<el-option label="否" value='0'></el-option>
					<el-option label="是" value='1'></el-option>
				</el-select>
			</el-form-item>
			<el-form-item v-show='chooseRenwuIndex==0' v-for="(item) in shiTingText.slice(2,4)" :key='item.id'
				:label="item.title">
				<el-input v-model="shiTingForm[item.formName]" :placeholder="'请输入'+item.title"></el-input>
			</el-form-item>
			<el-form-item v-show='chooseRenwuIndex==0&&shiTingForm.iscustom=="1"'
				v-for="(item) in shiTingText.slice(4,5)" :key='item.id' :label="item.title">
				<VueEditor use-custom-image-handler @image-added="handleImageAdded"
					v-model="shiTingForm[item.formName]"></VueEditor>
			</el-form-item>
			<el-form-item v-show='chooseRenwuIndex==0' v-for="(item) in shiTingText.slice(5,6)" :key='item.id'
				:label="item.title">
				<el-input-number style="width:30%" v-model="shiTingForm[item.formName]" :min='0'></el-input-number>
			</el-form-item>
			<el-form-item v-show='chooseRenwuIndex==0&&shiTingForm.iscustom=="0"'
				v-for="(item) in shiTingText.slice(6,7)" :key='item.id' :label="item.title">
				<el-input v-model="shiTingForm[item.formName]" :placeholder="'请输入'+item.title"></el-input>
			</el-form-item>

			<div v-show='chooseRenwuIndex==1'>
				<el-form-item>
					<el-button @click="addNewQuestion" type='warning'>新增题目</el-button>
				</el-form-item>
				<div v-for="(item) in playText" :key='item.id+item.id'>
					<el-form-item :label="item.questiontext">
						<div style="display:flex;width:50%;justify-content:space-between">
							<el-input v-if='item.texttype=="text"' v-model="playForm[item.keyname]"></el-input>
					
							<el-select style="width:100%" v-if='item.texttype=="select"' v-model="playForm[item.keyname]"
								placeholder="">
								<el-option v-for="(itemS) in item.options" :key="itemS.id" :label="itemS.answertext"
									:value="itemS.answertext"></el-option>
							</el-select>
					
							<el-input v-if='item.texttype=="textarea"' type='textarea' v-model="playForm[item.keyname]">
							</el-input>
					
							<div v-if='item.texttype=="radio"'>
								<el-radio v-for="(itemS) in item.options" :key="itemS.id" v-model="playForm[item.keyname]"
									:label="itemS.answertext">{{itemS.answertext}}</el-radio>
							</div>
					
							<el-checkbox-group v-if='item.texttype=="checkbox"' v-model="playForm[item.keyname]">
								<el-checkbox v-for="(itemS) in item.options" :key="itemS.id" :label="itemS.answertext">
									{{itemS.answertext}}</el-checkbox>
							</el-checkbox-group>
							<el-button
								style="margin-left:20px;line-height:30px;padding: 2px 10px;height:30px;display:flex;align-items:center"
								type="primary" @click='removeIpt(item)'>删除控件</el-button>
						</div>
					</el-form-item>
				</div>
			</div>
			
			
		</el-form>

		<div class='btn-list'>
			<el-button @click="sureCreate('allForm')" type="primary">确定</el-button>
			<el-button @click="cancelCreate()" type="info">取消</el-button>
		</div>


		<el-dialog title="控件操作" :visible.sync="questionFormDialog" v-if='questionFormDialog' width="30%" append-to-body
			center>
			<question :isAdd='isAdd' @dialogForm='questionFormDialog=false'></question>
		</el-dialog>
	</div>
</template>

<script>
	import {
		VueEditor
	} from "vue2-editor";
	import {
		communityOfUser
	} from '@/api/system';
	import {
		addTask,
		addShiTingInfo,
		addQuestionInfo,
		taskTypeDetail,
		updateTask
	} from '@/api/renwulist/renwu.js';
	import question from './question.vue'
	export default {
		props: {
			isAdd: {
				type: Object
			}
		},
		
		mounted() {
			if (this.isAdd.add) {
				this.firstForm('allForm')
			} else {
				console.log(this.isAdd)
				if (this.isAdd.lineData.questions == undefined) {
					this.chooseRenwuIndex = Number(this.isAdd.lineData.tasktype)
				} else {
					this.chooseRenwuIndex = Number(this.isAdd.lineData.taskInfo.tasktype)
				}
				taskTypeDetail({
					userid: this.GLOBAL.adminId(),
					uuid: this.isAdd.lineData.uuid
				}).then(res => {
					if (res.result == 200) {
						this.lineData = res.detail
						let data = res.detail
						let communitycodes = null
						if (data.tasktype == 0) {
							communitycodes = data.communitycodes.split(',')
							this.allForm = {
								communitycode: communitycodes,
								taskname: data.taskname,
								effecttime: data.effecttime,
								expiretime: data.expiretime,
								daybonus: data.daybonus,
								daylimit: data.daylimit,
								// isvisible: data.isvisible,
								taskdesc: data.taskdesc,
								sort:data.sort,
							}
							this.shiTingForm = {
								seentype: data.seentype,
								iscustom: data.iscustom,
								titleone: data.titleone,
								titletwo: data.titletwo,
								contents: data.contents,
								limitseconds: data.limitseconds,
								outlinkpath: data.outlinkpath,
							}
						} else {
							communitycodes = data.communitycodes
							this.allForm = {
								communitycode: communitycodes,
								taskname: data.taskInfo.taskname,
								effecttime: data.taskInfo.effecttime,
								expiretime: data.taskInfo.expiretime,
								daybonus: data.taskInfo.daybonus,
								daylimit: data.taskInfo.daylimit,
								// isvisible: data.taskInfo.isvisible,
								taskdesc: data.taskInfo.taskdesc,
								sort:data.taskInfo.sort,
							}
							var questions = data.questions;
							let arr = {}
							questions.map(item => {
								item.keyname = 'value' + item.id
								arr['value' + item.id] = null
								if (item.texttype == 'checkbox') {
									arr['value' + item.id] = []
								}
								item.questionflag = 0;
							})
							this.playText = questions;
							this.playForm = arr
						}
					} else {
						this.$message.error(res.description)
					}
				})
			}
			this.getShequCode()
		},
		data() {
			return {
				questionId: 100000, //问题渲染key
				lineData: {}, //修改的行数据
				renwuList: [{
						src: require('@/assets/imgs/index_blue.png'),
						title: '视听类'
					},
					{
						src: require('@/assets/imgs/index_green.png'),
						title: '问卷类'
					},
					{
						src: require('@/assets/imgs/index_red.png'),
						title: '操作类'
					}
				], //任务类型
				chooseRenwuIndex: null, //当前已选任务
				communitys: [], //登录用户可操作社区权限
				questionFormDialog: false, //新增问题选项
				allForm: {
					communitycode: [],
					taskname: '',
					effecttime: '',
					expiretime: '',
					daybonus: 1,
					daylimit: 1,
					// isvisible: '0',
					taskdesc: '',
					sort: '',
				}, //所有任务类填报表单
				allText: [{
						id: '0',
						title: '社区选择',
						formName: 'communitycode'
					},
					{
						id: '1',
						title: '任务名称',
						formName: 'taskname'
					},
					{
						id: '2',
						title: '生效时间',
						formName: 'effecttime'
					},
					{
						id: '3',
						title: '过期时间',
						formName: 'expiretime'
					},
					{
						id: '4',
						title: '单次奖励积分',
						formName: 'daybonus'
					},
					{
						id: '5',
						title: '单日获取次数上限',
						formName: 'daylimit'
					},
					{
						id: '6',
						title: '是否可见',
						formName: 'isvisible'
					},
					{
						id: '7',
						title: '任务描述',
						formName: 'taskdesc'
					},
					{
						id: '15',
						title: '任务排序',
						formName: 'sort'
					},
				], //所有任务类填报项
				shiTingForm: {
					seentype: '',
					iscustom: '',
					titleone: '',
					titletwo: '',
					contents: '',
					limitseconds: 0,
					outlinkpath: ''
				}, //视听类信息表单
				shiTingText: [{
						id: '8',
						title: '视听类型',
						formName: 'seentype'
					},
					{
						id: '9',
						title: '是否是自定义',
						formName: 'iscustom'
					},
					{
						id: '10',
						title: '一级标题',
						formName: 'titleone'
					},
					{
						id: '11',
						title: '二级标题',
						formName: 'titletwo'
					},
					{
						id: '12',
						title: '具体内容',
						formName: 'contents'
					},
					{
						id: '13',
						title: '要求观看时常（秒）',
						formName: 'limitseconds'
					},
					{
						id: '14',
						title: '外链地址',
						formName: 'outlinkpath'
					},
				], //视听类填报项
				playForm: {}, //问卷填报内容
				playText: [], //问卷类填报内容格式
				playText2: [], //问卷类填报内容格式
			};
		},
		components: {
			VueEditor,
			question
		},

		methods: {
			addNewQuestion() {
				this.questionId = this.questionId + this.playText.length
				this.questionFormDialog = true
			}, //新建题目
			chooseRenwu(index) {
				if (index == 2) {
					return
				}
				if (this.isAdd.add) {
					this.chooseRenwuIndex = index
				}
			}, //选择对应任务类型
			sureCreate(formName) {
				if (this.chooseRenwuIndex == null) {
					this.$message.error('请先选择添加任务类型')
					return
				}
				let loading = true
				this.$refs[formName].validate((valid) => {
					if (valid) {
						return
					} else {
						loading = false
					}
				})
				if (!loading) {
					return
				}
				//视听类型
				if(this.chooseRenwuIndex === 0){
					if(!this.shiTingForm.seentype){
						this.$message.error('请选择视听类型')
						return
					}
					if(!this.shiTingForm.iscustom){
						this.$message.error('请选择是否自定义')
						return
					}
					if(this.shiTingForm.iscustom === '1'){
						if(!this.shiTingForm.contents){
							this.$message.error('请输入内容')
							return
						}
					}
					if(!this.shiTingForm.titleone){
						this.$message.error('请输入一级标题')
						return
					}
					/* if(!this.shiTingForm.titletwo){
						this.$message.error('请输入二级标题')
						return
					} */
					if(this.shiTingForm.iscustom === '0'){
						if(!this.shiTingForm.outlinkpath){
							this.$message.error('请输入外链')
							return
						}
					}
				}
				//问卷类
				if(this.chooseRenwuIndex === 1){
					if(this.playText.length == 0){
						this.$message.error('请添加题目')
						return
					}
				}
				if (this.isAdd.add) {
					let form = {}
					form = this.allForm
					form.communitycodes = ''
					form.communitycode.map(item => {
						form.communitycodes = form.communitycodes + item + ','
					})
					form.communitycodes = form.communitycodes.slice(0, form.communitycodes.length - 1) //拼接社区字符串
					form.userid = this.GLOBAL.adminId()
					form.tasktype = this.chooseRenwuIndex
					addTask({
						userid: this.GLOBAL.adminId(),
						communitycodes:form.communitycodes,
						taskname:form.taskname,
						tasktype:this.chooseRenwuIndex,
						effecttime:form.effecttime,
						expiretime:form.expiretime,
						daybonus:form.daybonus,
						daylimit:form.daylimit,
						// isvisible:form.isvisible,
						taskdesc:form.taskdesc,
						sort:form.sort,
					}).then((res) => {
						if (res.result == 200) {
							if (form.tasktype == 0) { //新建视听类任务
								let shiTingForm = {}
								shiTingForm = this.shiTingForm
								shiTingForm.taskInfo = {
									uuid: res.detail
								}
								addShiTingInfo(shiTingForm).then((res) => {
									if (res.result == 200) {
										this.$message.success('新建视听类任务成功')
										this.cancelCreate()
										this.$parent.$parent.getList()
									} else {
										this.$message.error(res.description)
									}
								})
							} else if (form.tasktype == 1) { //新建问卷类任务
								this.playText.map(item => {
									item.taskInfo = {
										uuid: res.detail
									}
								})
								let form = this.playText
								addQuestionInfo(form).then(res => {
									if (res.result == 200) {
										this.$message.success('新建问卷类任务成功')
										this.cancelCreate()
										this.$parent.$parent.getList()
									} else {
										this.$message.error(res.description)
									}
								})
							}
						} else {
							this.$message.error(res.description)
						}
					})
				} else if (!this.isAdd.add) {
					let form = {}
					if (this.lineData.questions == undefined) {
						form = this.shiTingForm
						form.id = this.lineData.id
						form.communitycodes = this.allForm.communitycode
						form.taskInfo = this.allForm
						form.taskInfo.uuid = this.lineData.uuid
					} else {
						form.taskInfo = this.allForm
						form.taskInfo.uuid = this.lineData.taskInfo.uuid
						form.communitycodes = this.allForm.communitycode
						form.questions = this.playText.concat(this.playText2)
					}
					updateTask(form).then(res => {
						if (res.result == 200) {
							this.$message.success('修改赚积分任务成功')
							this.cancelCreate()
							this.$parent.$parent.getList()
						} else {
							this.$message.error(res.description)
						}
					})
				}
			}, //确认添加
			cancelCreate(formName) {
				this.firstForm('allForm')
				this.$emit('formDialog');
			}, //取消添加
			firstForm(formName) {
				this.allForm = {
					communitycode: [],
					taskname: '',
					effecttime: null,
					expiretime: null,
					daybonus: 1,
					daylimit: 1,
					// isvisible: '0',
					taskdesc: '',
					sort: '',
				}
				this.shiTingForm = {
					seentype: '',
					iscustom: '',
					titleone: '',
					titletwo: '',
					contents: '',
					limitseconds: 0,
					outlinkpath: ''
				}
				this.playForm = {}
				this.playText = []
				this.playText2 = []
				this.$refs[formName].resetFields();
			}, //初始化表单
			getShequCode() {
				communityOfUser({
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == 200) {
						this.communitys = res.detail;
					} else {
						this.$message.error(res.description);
					}
				})
			}, //获取社区操作权限选择 
			handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
				var formData = new FormData();
				formData.append("upfilelist", file);
				this.$axios({
						url: "/fileutil/MiniuploadObj",
						method: "POST",
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						data: formData
					})
					.then(result => {
						if (result.data.result == 200) {
							const url = result.data.detail[0].path;
							Editor.insertEmbed(cursorLocation, "image", url);
							resetUploader();
						} else {
							this.$message.warning(result.data.description)
						}

					})
					.catch(err => {
						console.log(err);
					});
			}, //富文本编辑器添加图片
			removeIpt(item) {
				this.$confirm('确认删除该控件吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let index = this.playText.indexOf(item)
					this.playText.splice(index, 1)
					if(this.isAdd.add == false){
						item.questionflag = 2;
						this.playText2.push(item);
						console.log(this.playText2);
					}
					
				})
			},
		}
	}
</script>
<style lang='scss' scoped>
	.row-flex {
		display: flex;
		align-items: center;
	}

	.renwu-choose {
		width: 100%;
		@extend .row-flex;
		justify-content: space-between;

		.renwu-item {
			width: 25%;
			height: 150px;
			position: relative;

			img {
				width: 100%;
				height: 80%;
			}

			p {
				widows: 100%;
				height: 20%;
				text-align: center;
			}

			.renwu-model {
				width: 100%;
				height: 80%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: darkgray;
				opacity: 0.5;
				@extend .row-flex;
				justify-content: center;
				font-size: 100px;
				border-radius: 5px;
			}
		}
	}

	.btn-list {
		@extend .row-flex;
		justify-content: center;

		button {
			width: 100px;
		}
	}
</style>
