<!--  -->
<template>
	<div>
		<el-form :model="addForm" label-width="150px" class="demo-ruleForm">
			<el-form-item label="控件类型">
				<el-select style="width:100%" v-model="chooseValue" placeholder="">
					<el-option v-for="(item) in choose" :key="item.name" :label="item.name" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="字段名称">
				<el-input v-model="addForm.questiontext"></el-input>
			</el-form-item>
			<el-form-item label="排列序号">
				<el-input v-model="addForm.sort"></el-input>
			</el-form-item>
			<el-form-item label="是否必填">
				<el-select style="width:100%" v-model="addForm.isnesscessary">
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="0"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="下拉选项" v-if="chooseValue=='select'">
				<el-button type="primary" @click="addChoose">添加选择项</el-button>
				<el-button type="info" @click="removeChoose">清空重新输入</el-button>
				<el-input style="margin-top:10px" v-for="(item,index) in addForm.xialaxuanze" :key='index+1'
					v-model="item.choose"></el-input>
			</el-form-item>
			<el-form-item label="单选选项" v-if="chooseValue=='radio'">
				<el-button type="primary" @click="addChoose">添加选择项</el-button>
				<el-button type="info" @click="removeChoose">清空重新输入</el-button>
				<el-input style="margin-top:10px" v-for="(item,index) in addForm.xialaxuanze" :key='index+1'
					v-model="item.choose"></el-input>
			</el-form-item>
			<el-form-item label="多选选项" v-if="chooseValue=='checkbox'">
				<el-button type="primary" @click="addChoose">添加选择项</el-button>
				<el-button type="info" @click="removeChoose">清空重新输入</el-button>
				<el-input style="margin-top:10px" v-for="(item,index) in addForm.xialaxuanze" :key='index+1'
					v-model="item.choose"></el-input>
			</el-form-item>
			<div style="text-align:center">
				<el-button type="primary" @click="chooseadd">确定</el-button>
				<el-button @click="closeDialog">关闭</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
	export default {
		props: {
			isAdd: {
				type: Object
			}
		},
		data() {
			return {
				choose: [{
						name: '单行输入框',
						value: 'text'
					},
					{
						name: '下拉框',
						value: 'select'
					},
					{
						name: '文本域',
						value: 'textarea'
					},
					{
						name: '单选按钮',
						value: 'radio'
					},
					{
						name: '多选按钮',
						value: 'checkbox'
					}
				],
				chooseValue: '',
				addForm: {
					questiontext: '',
					sort: '',
					isnesscessary: '',
					xialaxuanze: [{
							choose: ''
						},
						{
							choose: ''
						},
					],
				},
				questionId: 100,
				answerId: 1000
			};
		},

		components: {},

		computed: {},

		methods: {

			removeChoose() {
				this.addForm.xialaxuanze = [{
					choose: ''
				}, {
					choose: ''
				}]
			}, //清空多填项列表
			addChoose() {
				this.addForm.xialaxuanze.push({
					choose: ''
				})
			}, //增加多填项列表
			closeDialog() {
				this.$emit('dialogForm')
			},
			chooseadd() {
				if (this.chooseValue == '') {
					this.$message.error('请选择控件类型')
					return
				}
				if (this.addForm.questiontext == '') {
					this.$message.error('请填写题目')
					return
				}
				if (this.addForm.sort == '') {
					this.$message.error('请填写排序')
					return
				}
				if (this.addForm.isnesscessary == '') {
					this.$message.error('请选择必填')
					return
				}
				let xaunze = true
				if (this.addForm.chooseValue == 'select' || this.addForm.chooseValue == 'radio' || this.addForm
					.chooseValue == 'checkbox') {
					this.addForm.xialaxuanze.map(item => {
						if (item.choose == '') {
							this.$message.error('不能有选择项为空')
							xaunze = false
							return
						}
					})
				}
				if (!xaunze) {
					return
				}
				let newFormItem = {}
				newFormItem.questiontext = this.addForm.questiontext
				newFormItem.isnesscessary = this.addForm.isnesscessary
				newFormItem.sort = this.addForm.sort
				newFormItem.texttype = this.chooseValue
				if(this.isAdd.add == false){
					newFormItem.questionflag = 1;
				}
				newFormItem.options = []
				this.addForm.xialaxuanze.map((item, index) => {
					this.answerId++
					item.id = this.answerId
					item.sort = index + 1
					item.answertext = item.choose
					newFormItem.options.push(item)
				})
				newFormItem.id = this.$parent.$parent.questionId
				this.$parent.$parent.playText.push(newFormItem)
				let arr = {}
				this.$parent.$parent.playText.map(item => {
					item.keyname = 'value' + item.id
					arr['value' + item.id] = null
					if (item.texttype == 'checkbox') {
						arr['value' + item.id] = []
					}
				})
				this.$parent.$parent.playForm = arr
				this.closeDialog()
			}, //确认新增控件
		}
	}
</script>
<style lang='scss' scoped>
</style>
