<template>
	<div class="box">
		<div class='title' style=' padding-top:20px'>
			<el-form style="display:flex;flex-wrap:wrap" :model="searchlist" label-width="150px" ref='searchlist'>
				<!-- v-for循环截取循环的数组-->
				<el-form-item label-width="80px" v-for="(item) in searchType.slice(0,1)" :key='searchType.indexOf(item)'
					:label="item.title">
					<el-input style="width:200px !important" :placeholder="'请输入'+item.title+'查询'"
						v-model='searchlist[item.formName]'></el-input>
				</el-form-item>
				<el-form-item label-width="80px" v-for="(item) in searchType.slice(1,2)" :key='searchType.indexOf(item)'
					:label="item.title">
					<el-select style="width:200px !important" clearable :placeholder="'请输入'+item.title+'查询'"
						v-model='searchlist[item.formName]'>
						<el-option v-for="item in shequList" :label="item.name" :value="item.levelcode"
							:key='item.levelcode'></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label-width="80px" v-for="(item) in searchType.slice(2,3)" :key='searchType.indexOf(item)'
					:label="item.title">
					<el-select clearable style="width:200px !important" :placeholder="'请输入'+item.title+'查询'"
						v-model='searchlist[item.formName]'>
						<el-option label="视听类" value="0"></el-option>
						<el-option label="问卷类" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label-width="80px" v-for="(item) in searchType.slice(3,4)" :key='searchType.indexOf(item)'
					:label="item.title">
					<el-select clearable style="width:200px !important" :placeholder="'请选择'+item.title+'查询'"
						v-model='searchlist[item.formName]'>
						<el-option label="否" value="0"></el-option>
						<el-option label="是" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label-width="10px">
					<div style="display:flex;align-items:center">
						<el-button type='primary' @click='search'>
							<i class="el-icon-search" style="font-size: 14px;color: white;"></i>搜索
						</el-button>
						<el-button type='primary' @click='change({add:true})' v-if="taskOperatorType === ''">
							<i class="el-icon-plus" style="font-size: 14px;color: white;"></i>新增任务
						</el-button>
					</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="showdatanumber">共查询数据<span>{{ fenye.totalnum }}</span>条</div>
		<div>
			<el-table :data="tableData" border style="width: 100%;margin-top:20px" stripe>
				<el-table-column v-for='(item) in formtype.slice(0,3)' :prop="item.formvalue" show-overflow-tooltip
					:label="item.name" align="center" :key="formtype.indexOf(item)">
				</el-table-column>
				<el-table-column v-for='(item) in formtype.slice(3,4)' :prop="item.formvalue" show-overflow-tooltip
					:label="item.name" align="center" :formatter="taskType" :key="formtype.indexOf(item)">
				</el-table-column>
				<el-table-column v-for='(item) in formtype.slice(4,8)' :prop="item.formvalue" show-overflow-tooltip
					:label="item.name" align="center" :key="formtype.indexOf(item)">
				</el-table-column>

				<el-table-column prop="checkflag" label="活动审核状态" width="100px" align="center" :formatter="checkStatusFormatter">
				</el-table-column>
				<el-table-column label="审核意见" width="120px" show-overflow-tooltip align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.checkflag == 3">{{ scope.row.checkmsg }}</span>
						<span v-else>/</span>
					</template>
				</el-table-column>
				<el-table-column prop="activityflag" label="上下架状态"  width="100px"  align="center" :formatter="upDownStatusFormatter">
      </el-table-column>

				<el-table-column label="操作" width="300" align="center" v-if="taskOperatorType === 'checkOperator'">
					<div slot-scope="scope" style="display:flex;flex-wrap: wrap;justify-content:center">
						<el-button type="primary" @click="checkTask(scope.row)">审核</el-button>
					</div>
				</el-table-column>

				<el-table-column label="操作" width="300" align="center" v-else>
					<div slot-scope="scope" style="display:flex;flex-wrap: wrap;justify-content:center">
						<el-button type="primary" @click="change({add:false,lineData:scope.row})">修改</el-button>
						<el-button type="warning" @click="remove(scope.row)">删除</el-button>
						<el-button type="primary" v-if="scope.row.checkflag === null || scope.row.checkflag === '0' || scope.row.checkflag == 3" @click="checkSubmit(scope.row)">提交审核</el-button>
						<el-button type="success" v-if="scope.row.isvisible === '0' && scope.row.checkflag == 2" @click="upOrDown(scope.row)">上架</el-button>
						<el-button type="warning" v-if="scope.row.isvisible === '1' && scope.row.checkflag == 2" @click="upOrDown(scope.row)">下架</el-button>
					</div>
				</el-table-column><!-- 不同状态对应操作-->

				
				
			</el-table>
		</div>


		<div class="dialog">
			<el-dialog :close-on-click-modal="false" :visible.sync="formDialog" v-if='formDialog' append-to-body
				width="50%">
				<span style="font-size: 20px;color:white" slot="title">{{ isAdd.add == true? '新建任务':'编辑任务'}}</span>
				<newRenwu @formDialog='closeDialog' :isAdd='isAdd'></newRenwu>
			</el-dialog>
		</div>


		<div class='fenyekuang'>
			<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="currentPage"
				:page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
				layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>


		<!-- 任务审核 -->
    <el-dialog append-to-body title="审核" :close-on-click-modal='false' :visible.sync="checkDialogVisible"  width="500px" :before-close="checkhandleClose">
      <el-form :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="审核结果" prop="ispass">
          <el-radio-group v-model="form.ispass">
            <el-radio label="1">通过</el-radio>
            <el-radio label="0">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审核意见" prop="opinion" v-if="form.ispass === '0'">
          <el-input type="textarea" v-model="form.opinion"></el-input>
        </el-form-item>
        <el-form-item label-width="10px" style="margin-top: 40px; text-align: center;">
          <el-button type="primary" @click="submitForm('form')">提交审核</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
	</div>
</template>

<script>
	import dicts from '@/utils/dictionary'
	import {
		taskList,
		removeTask,
		upDownTask,
		submitCheck,
		auditTask
	} from '@/api/renwulist/renwu.js';
	import {
		isShequ
	} from '@/api/shequ/shequ.js'
	import newRenwu from './newRenwu'
	export default {
		components: {
			newRenwu,
		},
		mounted() {
			isShequ({
				userid: this.GLOBAL.adminId()
			}).then(res => {
				if (res.result == 200) {
					this.shequList = res.detail
					this.getList()
				} else {
					this.$message.error(res.description)
				}
			})
		},
		props:{
			taskOperatorType:{
				type: String,
				default: ''
			}
		},
		data() {
			return {
				shequList: [], //社区列表
				xiaoquList: [], //小区列表
				tableData: [], //获取的表格数据
				formDialog: false, //弹出框
				formtype: [{
						name: '序号',
						formvalue: 'xuhao'
					},
					{
						name: '活动名称',
						formvalue: 'taskname'
					},
					{
						name: '所属社区',
						formvalue: 'communitynames'
					},
					{
						name: '任务类型',
						formvalue: 'tasktype'
					},
					{
						name: '奖励积分',
						formvalue: 'daybonus'
					},
					{
						name: '生效时间',
						formvalue: 'effecttime'
					},
					{
						name: '过期时间',
						formvalue: 'expiretime'
					},
					{
						name: '任务描述',
						formvalue: 'taskdesc'
					},
				], //表单须填数据模板 
				searchlist: {
					shequ: '',
					renwuType: '',
					taskname: '',
					isvisible:''
					
				}, //搜索数据
				searchType: [{
						title: '活动名称',
						formName: 'taskname'
					},
					{
						title: '社区',
						formName: 'shequ'
					},
					{
						title: '任务类型',
						formName: 'renwuType'
					},
					{
						title: '是否上架',
						formName: 'isvisible'
					},
				], //搜索框成员
				currentPage: 1, //当前页
				fenye: {
					pagesizes: [10, 20, 50],
					pagesize: 10,
					totalnum: 0
				}, //分页数据
				accountUuid: '', //账户uuid
				isAdd: null, //判断是否是打开新增框
				checkDialogVisible: false,
				taskId: '',
				form: {
					ispass: '',
					opinion: ''
				},
				rules:{
					ispass: [
						{ required: true, message: '请选择审核结果', trigger: 'change' }
					],
					opinion: [
						{ required: true, message: '请输入审核意见', trigger: 'change' }
					]
				},
				checkStatus: dicts.checkStatus,
      	upDownStatus: dicts.taskUpDownStatus,
			}
		},
		activated() {
			this.getList()
		},
		methods: {
			//审核状态
			checkStatusFormatter(row, column) {
				if(!row.checkflag){
					return "未提交审核"
				}
				let status = null
				this.checkStatus.map(item=>{
					if(row.checkflag == item.value){
						status = item.label;
					}
				})
				return status
			},
			//上架或下架状态
			upDownStatusFormatter(row, column) {
				let status = null
				this.upDownStatus.map(item=>{
					if(row.isvisible == item.value){
						status =  item.label;
					}
				})
				return status
			},
			taskType(row) {
				if (row.tasktype == 0) {
					return '视听类任务'
				} else if (row.tasktype == 1) {
					return '问卷类任务'
				} else if (row.tasktype == 2) {
					return '操作类任务'
				}
		 },
			search() {
				this.currentPage = 1
				this.getList()
			}, //搜索请求
			sizeChange(val) {
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList()
			}, //展示条数改变时
			currentChange(val) {
				this.currentPage = val;
				this.getList()
			}, //当前页改变时
			getList() {
				let arealevelArr = []
				if (this.searchlist.shequ == '') {
					this.shequList.map(item => {
						arealevelArr.push(item.levelcode)
					})
				} else {
					arealevelArr = [this.searchlist.shequ];
				}
				taskList({
					userid: this.GLOBAL.adminId(),
					communitycodes: arealevelArr.join(','),
					taskname: this.searchlist.taskname,
					tasktype: this.searchlist.renwuType,
					isvisible: this.searchlist.isvisible,
					checkflag: this.taskOperatorType === 'checkOperator'? '1': '',
					pageSize: this.fenye.pagesize,
					pageNum: this.currentPage,
				}).then(res => {
				 if (res.result == 200) {
						console.log(res)
						this.tableData = res.detail.list
					 this.tableData.map((item, index) => {
							item.xuhao = index + 1;
							item.effecttime = item.effecttime.slice(0,10);
							item.expiretime = item.expiretime.slice(0,10);
					 })
						this.fenye.totalnum = res.detail.totalRow
					} else {
						this.$message.error(res.description);
					}
				})
			}, //获取表格数据和查询请求
			closeDialog() {
				this.formDialog = false
			}, //关闭表格
			change(row) {
				// console.log(row)
				if(row.lineData && row.lineData.isvisible === '1'){
					this.$message.warning('请先下架任务，再进行修改')
					return false;
				}
				this.isAdd = row
				this.formDialog = true
			}, //详情框
			remove(row) {
				this.$confirm('确定删除该任务吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					removeTask({
						userid: this.GLOBAL.adminId(),
						taskid: row.uuid
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('删除成功')
							this.getList()
						} else {
							this.$message.error(res.description)
						}
					})
				})
			}, //删除任务
			//上架/下架
    	upOrDown(row){
				// console.log('1上架/0下架')

				let activityflag = row.isvisible;
				let newTaskFlag = null;
				let tipMsg = null;
				if(activityflag === '1'){  //上架状态
					newTaskFlag = '0';
					tipMsg = "是否确认下架？"
				}else if(activityflag === '0'){  //下架状态
					newTaskFlag = '1'
					tipMsg = "是否确认上架？"
				}

				this.$confirm(`${tipMsg}`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					
					upDownTask({
						userid: this.GLOBAL.adminId(),
						taskid: row.uuid,
						isvisible: newTaskFlag
					}).then(res=>{
						if(res.result == 200){
							this.$message.success(res.detail);
							this.getList();
						} else {
							this.$message.error(res.description);
						}
					})

				}).catch(() => {
									
				});

			},
			//提交审核
			checkSubmit(row){
				console.log('提交审核',row)
				this.$confirm('是否确认提交审核?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						submitCheck({
							userid: this.GLOBAL.adminId(),
							taskid: row.uuid
						}).then(res=>{
							if(res.result == 200){
								this.$message.success('已提交审核，请等待审核');
								this.getList();
							} else {
								this.$message.error(res.description);
							}
						})
					}).catch(() => {
									
					});
				
			},
			//审核任务
			checkTask(row){
				console.log('checkTask', row)
				this.checkDialogVisible = true;
				this.taskId = row.uuid;
			},
			//审核表单重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			//关闭审核表单
			checkhandleClose(done) {
				this.resetForm('form')
				done();
			},
			//审核按钮
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$confirm('是否确认提交审核?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							auditTask({
								userid: this.GLOBAL.adminId(),
								taskid: this.taskId,
								ispass: this.form.ispass,
								opinion: this.form.opinion
							}).then(res=>{
								if(res.result == 200){
									this.$message.success('操作成功');
									this.resetForm('form');
									this.checkDialogVisible = false;
									this.taskId = '';
									this.getList();
								} else {
									this.$message.error(res.description);
								}
							})
						}).catch(() => {
										
						});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
		},
	}
</script>
<style lang='scss'>
	.showdatanumber {
		margin: 0 10px 10px;
		color: #409EFF;

		span {
			margin: 0 5px;
			font-size: 20px;
		}
	}

	.box {
		margin: 10px;
		position: relative;
		z-index: 1;

		.el-table th,
		.el-table tr {
			background-color: transparent;
		}

		.el-table {
			background-color: transparent;
		}
	}
</style>
<style scoped>
	.playBtn {
		padding: 5px !important;
		margin-left: 4px !important;
	}

	.title {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.fenyekuang {
		width: 100%;
		height: 50px;
		margin-top: 1px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
</style>
